import React from 'react'

const Logo: React.FC = () => {
  return (
    <svg width='1.5em' height='1.5em' viewBox='0 0 73 68' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M33.6363 6.48943C20.205 8.1872 9.81812 19.6533 9.81812 33.5455C9.81812 47.4376 20.205 58.9037 33.6363 60.6015V53.7095C23.9847 52.0678 16.6364 43.6647 16.6364 33.5454C16.6364 23.4261 23.9847 15.0231 33.6363 13.3814V6.48943ZM40.4545 53.7248C50.1509 52.1206 57.5455 43.6966 57.5455 33.5454C57.5455 23.3943 50.1509 14.9703 40.4545 13.3661V6.47809C53.9298 8.13548 64.3636 19.622 64.3636 33.5455C64.3636 47.4689 53.9298 58.9554 40.4545 60.6128V53.7248Z'
        fill='#353535'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M47.2728 0H40.4546V11.5455H47.2728V0ZM47.2728 57.8182H40.4546V67.0909H47.2728V57.8182Z'
        fill='#353535'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M33.6365 0.0909119H26.8184V11.6364H33.6365V0.0909119ZM33.6365 57.9091H26.8184V67.1818H33.6365V57.9091Z'
        fill='#353535'
      />
      <rect x='61' y='30.0909' width='12' height='6.81818' fill='#353535' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M54.9623 36.9091C53.3846 45.3437 45.9833 51.7273 37.091 51.7273C27.0495 51.7273 18.9092 43.587 18.9092 33.5455C18.9092 23.5039 27.0495 15.3636 37.091 15.3636C45.9513 15.3636 53.3314 21.7015 54.9451 30.0909H47.92C46.458 25.5041 42.1625 22.1818 37.091 22.1818C30.815 22.1818 25.7273 27.2695 25.7273 33.5455C25.7273 39.8214 30.815 44.9091 37.091 44.9091C42.1959 44.9091 46.5146 41.5429 47.9486 36.9091H54.9623Z'
        fill='#353535'
      />
      <path
        d='M-5.10629e-07 33.5909L14.1818 23.4742L14.1818 43.7077L-5.10629e-07 33.5909Z'
        fill='#353535'
      />
    </svg>
  )
}

export default Logo
